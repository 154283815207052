
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import {
  AddTicketCategory,
  CategoryTypes,
} from "@/store/modules/category/category.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const categoryX = namespace("Category");

@Component({
  components: { DatePicker },
})
export default class AddCategory extends Mixins(ValidationMixin) {
  @categoryX.State(CategoryTypes.ADD_CATEGORY_STATE)
  public addCategory!: DefaultState;

  @categoryX.Mutation(CategoryTypes.SET_ADD_CATEGORY_DIALOG)
  public setAddCategory!: (addCategory: boolean) => void;

  @categoryX.Action(CategoryTypes.ADD_CATEGORY)
  public saveCategory!: (farmer: AddTicketCategory) => Promise<void>;

  public addCategoryStep = 1;

  public isValid = false;

  public category: AddTicketCategory = {
    name: "",
    description: "",
    active: true,
  };

  @Ref("form") public formRef!: Validator;

  closeAddCategoryDialog(): void {
    this.setAddCategory(false);
  }

  async saveCategoryToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveCategory(this.category);
    }
  }

  // mounted(): void {}
}
